import React , { useState, useEffect } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import ScrollToTopButton from './ScrollToTopButton'

import logo1 from '../assets/logo12.png'
import logo2 from '../assets/logo3.png'
import logo3 from '../assets/logo1.jpg'
import logo4 from '../assets/logo17.png'
import logo5 from '../assets/logo7.png'
import logo7 from '../assets/logo9.jpg'
import logo8 from '../assets/logo8.png'
import logo11 from '../assets/logo11.png'
import logo10 from '../assets/logo10.png'
import logo12 from '../assets/logo2.png';

import Fade from 'react-reveal/Fade';

const Clients = () => {
//Counter of clients
  const [ currentIndex2, setCurrentIndex2] = useState(0);
  useEffect(() => {
    
    let interval2 = setInterval(() => {
      setCurrentIndex2((currentIndex) => currentIndex + 1);
    }, 5);
    return () => 
    clearInterval(interval2);
    
   
  }, []);
  useEffect(() => {
    document.title='Clients-birthmark limited'
    const canonicalLink = document.createElement('link');
    canonicalLink.rel = 'canonical';
    canonicalLink.href = 'https://www.birthmark.africa/clients';
    document.head.appendChild(canonicalLink);

    return () => {
      document.head.removeChild(canonicalLink);
    };
  }, []);

  
  return (
    <div id="clients" className="w-full h-screen py-14 mt-6">
<Navbar title ='Clients - Birthmark Limited'/>
<div className="flex flex-wrap py-6 justify-center">
    
    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-4">
          <div className="bg-gray-100  rounded-lg p-6 with-shadow">
          
            <h2 className="text-3xl font-josefin flex justify-center text-[#4c4c62] font-bold">
              {currentIndex2 > 188 ? 188 : currentIndex2} 
            </h2>
            <p className='flex justify-center font-semibold font-nunito'>Total Clients</p>
           
          </div>
        </div>
        

        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-4">
          <div className="bg-gray-100 rounded-lg p-6 with-shadow">
         
            <h3 className="text-3xl flex font-josefin justify-center text-[#4c4c62] font-bold">
              {currentIndex2 > 88 ? 88 : currentIndex2} 
            </h3>
            <p className='flex justify-center font-semibold font-nunito'>Brokers</p>
            
          </div>
        </div>

        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-4">
          <div className="bg-gray-100 rounded-lg p-6 with-shadow">
          
            <h4 className="text-3xl font-josefin flex justify-center text-[#4c4c62] font-bold">
              {currentIndex2 > 96 ? 96 : currentIndex2} 
            </h4>
            <p className='flex justify-center font-semibold font-nunito'>Agents </p>
           
          </div>
        </div>

        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-4">
          <div className="bg-gray-100 rounded-lg p-6 with-shadow">
           
            <h5 className="text-3xl font-josefin flex justify-center text-[#4c4c62] font-bold">
              {currentIndex2 > 4 ? 4 : currentIndex2} 
            </h5>
            <p className='flex justify-center font-semibold font-nunito'>Bank Assurance</p>
          </div>
          </div>
        </div>
        <div className="mx-auto  flex justify-center">
<p className='font-semibold text-[#000e5d] font-josefin flex justify-center md:text-xl'> 
Our clients include big and small insurance intermediaries based in East Africa
 </p>
  </div>
  <h6 className='font-josefin text-[#000e5d] mb-1 font-semibold flex justify-center md:text-xl'>
    consisting of Insurance Brokers, Bank Assurance and Agents.
  </h6>

      {/* logos of some of the cliets */}
      <div 
      className="mx-auto mt-16  flex justify-center">
        <p className="font-semibold font-josefin text-2xl text-center italic relative">
          <span className="before-after-line"></span>
          Our Clients
          <span className="before-after-line"></span>
        </p>
      </div>

<div className="flex justify-center  m-4">
 
  <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 mx-auto relative">
  <Fade left>
    <div className=" rounded-lg hover:scale-105 transition-all">
      <img src={logo1} className="w-40 h-40 sm:w-50 sm:h-50 " alt="birthmark limited" />
    </div>
    <div className=" rounded-lg hover:scale-105 transition-all">
      <img src={logo2} className="w-40 h-40 sm:w-50 sm:h-50 " alt="birthmark limited" />
    </div>
    <div className=" rounded-lg hover:scale-105 transition-all">
      <img src={logo7} className="w-40 h-40 sm:w-50 sm:h-50 " alt="birthmark limited" />
    </div>
    <div className=" rounded-lg  hover:scale-105 transition-all">
      <img src={logo10} className="w-40 h-40 sm:w-50 sm:h-50 " alt="birthmark limited" />
    </div>
    <div className="m-2 rounded-lg hover:scale-105 transition-all">
      <img src={logo5} className="w-40 h-40 sm:w-50 sm:h-50 " alt="birthmark limited" />
    </div>
    </Fade>
  </div> 
</div>
<div className="flex justify-center p-4 m-4">
  <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mx-auto  ">
  <Fade right>
    <div className=" rounded-lg hover:scale-105 transition-all">
      <img src={logo12} className="w-40 h-40  sm:w-50 sm:h-50 " alt="birthmark limited" />
    </div>
    <div className=" rounded-lg  hover:scale-105 transition-all">
      <img src={logo3} className="w-40 h-40 sm:w-50 sm:h-50 " alt="birthmark limited" />
    </div>
    <div className=" rounded-lg hover:scale-105 transition-all">
      <img src={logo11} className="w-40 h-40 sm:w-50 sm:h-50 " alt="birthmark limited" />
    </div>
    <div className=" rounded-lg hover:scale-105 transition-all">
      <img src={logo8} className="w-40 h-40 sm:w-50 sm:h-50 " alt="birthmark limited" />
    </div>
    <div className="m-2 rounded-lg hover:scale-105 transition-all">
      <img src={logo4} className="w-40 h-40 sm:w-50 sm:h-50 " alt="birthmark limited" />
    </div>
    </Fade>
  </div>
</div>
{/* Insurance brokers */}
      <div className="w-full  p-4">
    <div className="bg-gray-100 rounded-lg p-6 with-shadow">
      <Fade top>
      <h4 className="text-2xl font-josefin flex justify-center text-[#4c4c62] font-bold">
      Insurance Brokers
      </h4>
      </Fade>
      <Fade bottom>
      <div className="grid grid-cols-2  md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4 font-nunito text-sm">
        <div>
           <ul>
            <li>1. ABC Insurance Brokers </li>  
            <li>2. Acentria Insurance Brokers</li>
            <li>3. Afrishield Insurance Brokers</li>
            <li>4. Allicom Insurance Brokers</li>
            <li>5. Amro Insurance Brokers</li>
            <li>6. AMS Insurance Brokers</li>
            <li>7. Anant Bhatt Insurance Brokers</li>
            <li>8. APIS Insurance Brokers</li>
            <li>9. A-Plan Insurance Brokers</li>
            <li>10. Assured Insurance Brokers</li> 
            <li>11. Arcadia Insurance Brokers</li>
            <li>12. Artha Insurance Brokers</li>
            <li>13. AssureLink Insurance Brokers</li> 
            <li>14. Avocet Insurance Brokers</li>
            <li>15. Bafana Insurance Brokers</li>
            <li>16. Blue Cover Insurance Brokers</li>
            <li>17. Bottomry Insurance Brokers</li>
            <li>18. Busam Insurance Brokers </li>
            <li>19. Chartwell Insurance Brokers</li>
            <li>20. Consolidated Insurance Brokers</li>
            <li>21. Covermax Insurance Brokers</li> 
            <li>22. Disney Insurance Brokers</li>
            
            
          </ul>
        </div>
        <div>
          <ul>
          <li>23. E Insurance Brokers</li>
            <li>24. Enwealth Insurance Brokers</li>
            <li>25. Ensign Insurance Brokers</li>
            <li>26. ETG Insurance Brokers</li>
            <li>27. Fairsure Insurance Brokers</li>
            <li>28. Farelane Insurance Brokers</li>
            <li>29. Formax Insurance Brokers</li>
            <li>30. First Accord Insurance Brokers</li>
            <li>31. First American Insurance Brokers</li>
            <li>32. Grail Insurance Brokers</li>
            <li>33. Hawk Bay Insurance Brokers</li>
            <li>34. Hillspan Insurance Brokers</li>
            <li>35. HP Insurance Brokers</li>
            <li>36. Integrated Insurance Brokers</li>
            <li>37. Inter Oceans Insurance Brokers</li>
            <li>38. K-Sembi Insurance Brokers</li>
            <li>39. Karen Direct Insurance Brokers</li>
          <li>40. Kava Insurance Brokers</li>
          <li>41. Kenfident Insurance Brokers</li>   
          <li>42. Laser Insurance Brokers</li>
          <li>43. Losagi Insurance Brokers </li>
          <li>44. M A Khan Insurance Brokers</li>
            
          </ul>
        </div>
        
        <div>
          <ul>
          
         
          <li>45. Macly Insurance Brokers</li>
          <li>46. Maj Insurance Brokers</li>
          <li>47. Meister Insurance Brokers</li>
          <li>48. Methodist Insurance Brokers</li>
          <li>49. Midline Insurance Brokers</li>
          <li>50. Mima Insurance Brokers</li>
          <li>51. Miran Insurance Brokers</li>
          <li>52. New Chapter Insurance Brokers</li>
          <li>53. Nile Capital Insurance Brokers</li>
          <li>54. Northridge Insurance Brokers</li>
          <li>55. Nyadwe Insurance Brokers</li>
          <li>56. Octagon Insurance Brokers</li>
          <li>57. Octagon Insurance Brokers(Uganda)</li>
          <li>58. Octagon Insurance Brokers(Zambia)</li>
          <li>59. Olea Kenya Insurance Brokers</li>
          <li>60. Options Insurance Brokers</li>
          <li>61. Package Insurance Brokers</li>
          <li>62. Paladin Insurance Brokers</li>
          <li>63. Pelican Insurance Brokers</li>
          <li>64. Policy Mart Insurance Brokers</li>
          <li>65. Primeken Insurance Brokers</li>
          <li>66. Qijito Insurance Brokers</li>
          
          </ul>
        </div>
        <div>
          <ul>
     
          <li>67. Reica Insurance Brokers</li>
          <li>68. Reval Insurance Brokers</li>
          <li>69. Risk Solutions Insurance Brokers</li>  
          <li>70. Roberts Insurance Brokers</li>
          <li>71. Samnel Insurance Brokers</li>
          <li>72. Sapon Insurance Brokers</li>
          <li>73. Sasala Insurance Brokers</li>
          <li>74. Scanner Insurance Brokers</li>
          <li>75. Shree Insurance Brokers</li>
          <li>76. Smartguard Insurance Brokers</li>        
          <li>77. Spectrum Insurance Brokers (Uganda)</li>
          <li>78. Stansfeld Insurance Brokers</li>
          <li>79. Surepoint Insurance Brokers</li>
          <li>80. Sunland Insurance Brokers</li>
          <li>81. Superlink Insurance Brokers</li>
          <li>82. Unipolar Insurance Brokers</li>
          <li>83. Utmost Insurance Brokers</li>
          <li>84. Vefis Insurance Brokers</li>
          <li>85. Verge Insurance Brokers</li>
          <li>86. Victoria Insurance Brokers</li>
          <li>87. Viva 365 Insurance Brokers</li>
          <li>88. Wakiba Insurance Brokers
</li>
          </ul>
        </div>
        
      </div>
      </Fade>
    </div>
  </div> 
{/* Insurance Agents */}
  <div className="w-full  p-4 ">
    <div className="bg-gray-100 rounded-lg p-6 with-shadow">
      <Fade top>
      <h5 className="text-2xl font-josefin flex justify-center text-[#4c4c62] font-bold">
      Insurance Agents
      </h5>
      </Fade>
      <Fade bottom>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4 font-nunito text-sm ">
        <div>
          <ul>
          <li>1. Abiria Insurance Agencies </li>
          <li>2. Aloha Insurance Agency </li>
            <li>3. APF Insurance Agency </li>
            <li>4. Axe Insurance Agency</li>
            <li>5. Belven Insurance Agency</li>
            <li>6. Bluenet Insurance Agency</li>
            <li>7. Bristal Corporation Ins. Agency</li>
            <li>8. Broad Insurance Agency </li>
            <li>9. Canei Insurance Agency</li>  
            <li>10. Clearways Insurance Agency</li> 
            <li>11. Convenor Insurance Agency</li> 
            <li>12. CoTrust Insurance Agency</li>
            <li>13. Daybreak Insurance Agency </li>
            <li>14. D-Risk Insurance Agency</li>
            <li>15. Datani Insurance Agency</li>         
            <li>16. Ease Insurance Agency </li>
            <li>17. End to End Insurance Agency </li>
            <li>18. Eritims Insurance Agency</li>
            <li>19. Express Indemnity Insurance Agency</li>
            <li>20. Extra Miles Insurance Agency</li>
          <li>21. Fan Insurance Agency</li>
          <li>22. Faraja Insurance Agency</li>
            <li>23. Fast Target Insurance Agency</li>
            <li>24. Felicity Insurance Agency</li>
          

            
          
          </ul>
  
          
          
        </div>
        <div>
          <ul>
           
            
            
            <li>25. Fenhouse Insurance Agency </li>
            <li>26. FinCorp Insurnce Agency</li>
            <li>27.  Finnet Insurance Agency</li>
            <li>28. Flexico Insurance Agency </li>
            <li>29. Fullbloom Insurance Agency</li>
          <li>30. Grest Insurance Agency </li>
            <li>31. Haivan Insurance Agency</li>
            <li>32. Hakad Insurance Agency</li>
            <li>33. Hifadhi Africa Insurance Agency</li>
            <li>34. Horeb Insurance Agency</li>
            <li>35. Intaru Insurance Agency</li>        
            <li>36. Insta Africa Insurance Agency</li>  
            <li>37. Insure Place Insurance Agency Ltd</li>
            <li>38. Insuraquality Insurance Consultants</li> 
            <li>39. Jahazi Insurance Agency</li>
            <li>40. Jimmy’s Insurance Agency</li>     
          <li>41. Keensell Insurance Agency</li>
          <li>42.  Kentab Insurance Agency</li>
          <li>43. Keysons Insurance Agency</li>
          <li>44. Kimsure Insurance Agency</li>
          <li>45.  Kinetic Insurance Agency</li>
          <li>46.  Kimisitu Insurance Agency</li> 
          <li>47. Kuality kit Insurance Agency </li>
          <li>48. Ladybird Insurance Agency </li>
          
          </ul>
        </div>
        
        <div>
          <ul>
         
          <li>49.  Lemco Insurance Agency </li>
          <li>50. Luxe Insurance Agency</li>
          <li>51. Max & Sirch Insurance Agency</li>
          <li>52. Mcover Insurance Agency </li>
          <li>53. Mentors Insurance Agencies</li>
          <li>54. Millenia Insurance Agency</li>
          <li>55. MinMax Insurance Agency</li>
          <li>56. Misha Insurance Agency</li> 
          <li>57. Mishan Insurance Agency </li>
          <li>58. MLG Insurance Agencies</li>
          <li>59. Mr. Insurance Agency</li>
          <li>60. Zahari Insurance</li>
          <li>61. Nanap Ventures And Insurance Agency</li>
          <li>62. Natbah Insurance Agency</li>
          <li>63. Ndemi Insurance Agencies</li>
          <li>64. Overleap Insurance Agency</li>
          <li>65. Pesa Bazaar Insurance Agency</li>
          <li>66. Pitta Insurance Agency</li>
          <li>67. Prime Link Insurance Agency</li>
          <li>68. Prime Merchants Insurance Agency</li>
          <li>69. Privileged Insurance Agencies</li>
          <li>70. Raptor Insurance Agency </li>
          <li>71. Rency Insurance Agency</li>
          <li>72. Renshield Insurance Agency</li>
          
          </ul>
        </div>
        
        <div>
          <ul>
          <li>73. Revolutionary Insurance Consultants </li>
            <li>74. Rhetse Insurance Agency</li>
            <li>75. Riann Insurance Agency</li>
          <li>76. Right Insurance Agency</li>
         <li>77. Royal Prestige Insurance Agency</li>
         <li>78. Russet Insurance Agency</li> 
          <li>79. Safechoice Insurance Agency</li>
          <li>80. Safetra Insurance Agency</li>
          <li>81. Sam - Ville Insurance Agency</li>
          <li>82. Sanctum Insurance Agency</li>
          <li>83. Sea Breeze Insurance Agency</li>
          <li>84. Serv All Insurance Agency</li>
          <li>85. Shimin Insurance Agency</li>
          <li>86. Sienna Insurance Agency</li>  
          <li>87. Silverguard Insurance Agency</li>
          <li>88. Solution Point Insurance Agency</li>
          <li>89. Spring Board Capital Insurance Agency</li>
          <li>90. SparkHill Insurance Agency</li>
          <li>91. Stoke Insurance Agency</li>
          <li>92. Straightway Insurance Agency</li> 
          <li>93. The Blue Diamond Insurance Agency</li>
          <li>94. Tiqvah Insurance Agency</li>
          <li>95. Trian Insurance Agency</li>
          <li>96. Unison Insurance Agency</li>
          
          </ul>
        </div>
      </div>
      </Fade>
    </div>
  </div>
  {/* Bank Assurance */}
  <div className="flex flex-wrap justify-center ">
  <div className="w-full sm:w-1/2 md:w-1/3  ">
          <div className="bg-gray-100 rounded-lg p-6 lg:m-4 with-shadow">
          <Fade top>
            <h3 className="text-2xl font-josefin flex justify-center text-[#4c4c62] font-bold">
            Bank Assurance
            </h3>
            </Fade>
            <Fade bottom>
            <p className='flex justify-center text-sm'>
              <ul className='font-nunito '>
              <li>1. Prime Bank Insurance Agency</li>
              <li>2. Maisha Micro Finance Insurance Agency </li>
              <li>3. SMEP Insurance Agency</li>
              <li>4. Sumac Insurance Agency</li>
                 </ul>
            </p>
            </Fade>
          </div>
        </div>
     </div>
<ScrollToTopButton/>
<Footer/>
    </div>
  )
}

export default Clients